export default function Wheel(){
    return (
        <div id="wheel-wrapper">
            <div className="wheel" id="wheel">
                <div className="center"></div>
                <div className="faixa">
                    <span>20% OFF</span>
                </div>
                <div className="faixa">
                    <span>1 Aula gratuita</span>
                </div>
                <div className="faixa">
                    <span>40% OFF</span>
                </div>
                <div className="faixa">
                    <span>Bola Personalizada</span>
                </div>
                <div className="faixa">
                    <span>20% OFF</span>
                </div>
                <div className="faixa">
                    <span>1 Aula gratuita</span>
                </div>
                <div className="faixa">
                    <span>40% OFF</span>
                </div>
                <div className="faixa">
                    <div>
                        <span>Bola Personalizada</span>
                    </div>
                </div>
            </div>
            <div id="seta"></div>
        </div>
    );
}
