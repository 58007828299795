import { useState, useEffect } from 'react';
import './App.css';
import Form from './components/Form';
import Roleta from './components/Roleta';
import wizkids from './images/wizkids.jpg';
import wizkidsMobile from './images/wizkids-mobile.jpg';

function App() {
  const [rodando,setRodando]=useState(false);
  const [deg,setDeg]=useState(0);
  const [prize,setPrize]=useState('');

  let currentDeg=deg;
  let counter=0;
  let speed=0;
  let time=Math.floor(Math.random()*(130-80+1)+80);
  let intervalId=null;

  useEffect(()=>{
    if(rodando){
      spin();
    }
  },[rodando]);

  function spin(){
    intervalId=setInterval(()=>{
      if(counter<8){
        speed=Math.pow((counter/2),2);
      }
      if(counter>=time&&speed>0){
        speed-=0.5;
        speed=speed<0?0:speed;
      }

      document.getElementById('wheel').style.transform=`rotate(${currentDeg}deg)`;
      currentDeg=(currentDeg+speed)%360;
      if(speed==0&&counter>=time){
        setDeg(currentDeg);
        setRodando(false);
        time=Math.floor(Math.random()*(130-80+1)+80);
        clearInterval(intervalId);

        if(currentDeg>=0&&currentDeg<45||currentDeg>=180&&currentDeg<225){//20%off
          setPrize('Bola personalizada Wizard');
        }else if(currentDeg>=45&&currentDeg<90||currentDeg>=225&&currentDeg<270){//1 aula gratuita
          setPrize('40% OFF');
        }else if(currentDeg>=90&&currentDeg<135||currentDeg>=270&&currentDeg<315){//40%off
          setPrize('1 aula gratuita');
        }else if(currentDeg>=135&&currentDeg<180||currentDeg>=315&&currentDeg<360){//bola personalizada
          setPrize('20% OFF');
        }
      }
      counter++;
    },46);
  }

  return (
    <>
      <div class="desktop-banner">
        <img src={wizkids} />
      </div>
      <div class="mobile-banner">
        <img src={wizkidsMobile} />
      </div>
      <div className="container">
        <div>
          <Roleta rodando={rodando} setRodando={setRodando} />
        </div>
        <div>
          <Form rodando={rodando} setRodando={setRodando} prize={prize} />
        </div>
      </div>
    </>
  );
}

export default App;
