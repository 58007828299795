import { useEffect, useState } from "react";
import api from '../services/api';
import InputMask from 'react-input-mask';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal);

export default function Form({rodando,setRodando,prize}){
    const [name,setName]=useState('');
    const [email,setEmail]=useState('');
    const [telefone,setTelefone]=useState('');

    useEffect(async ()=>{
        if(prize){
            let acoes={
                '40% OFF': 7,
                '20% OFF': 8,
                'Bola personalizada Wizard': 9,
                '1 aula gratuita': 10,
            };
            try{
                var params=new URLSearchParams();
                params.append('nome',name);
                params.append('email',email||' ');
                params.append('mensagem',`Ganhou o prêmio: ${prize}`);
                params.append('acao_id',acoes[prize]);
                params.append('telefone',telefone);
                params.append('Unidade',1);
                params.append('origem',34);
                const response=await api.post('/add_lead',params).then((response)=>{
                    MySwal.fire({
                        title: 'Parabéns',
                        html: `Em breve alguém da nossa equipe entrará em contato<br>Prêmio: <b>${prize}</b>`,
                        icon: 'success',
                        confirmButtonText: 'Ok',
                    });
                }).catch((error)=>{
                    MySwal.fire({
                        title: 'Erro!',
                        text: 'Por favor, tente novamente mais tarde',
                        icon: 'error',
                        confirmButtonText: 'Ok',
                    });
                });
                setName('');
                setEmail('');
                setTelefone('');
            }catch(error){
                //console.log(error);
            }
        }
    },[prize]);

    function handleSubmit(e){
        e.preventDefault();
        if(!name){
            MySwal.fire({
                title: 'Atenção',
                text: 'É necessário informar o seu nome',
                icon: 'warning',
                toast: true,
                position: 'top',
                showConfirmButton: false,
                timer: 3000,
            });
            return;
        }
        if(!telefone){
            MySwal.fire({
                title: 'Atenção',
                text: 'É necessário informar o seu telefone',
                icon: 'warning',
                toast: true,
                position: 'top',
                showConfirmButton: false,
                timer: 3000,
            });
            return;
        }
        setRodando(true);
    }
    function handleTerms(e){
        e.preventDefault();
        MySwal.fire({
            title: 'Termos de uso',
            html: `Lorem ipsum dolor sit amet`,
            icon: false,
            confirmButtonText: 'Ok',
        });
    }
    function handlePrivacy(e){
        e.preventDefault();
        MySwal.fire({
            title: 'Política de privacidade',
            html: `Lorem ipsum dolor sit amet`,
            icon: false,
            confirmButtonText: 'Ok',
        });
    }

    return (
        <>
            <h2>Cadastre-se para receber seu prêmio</h2>
            {prize?(
                <div>
                    <p>Parabéns!</p>
                    <p>O seu prêmio é: <strong>{prize}</strong></p>
                </div>
            ):(
                <>
                    <p>{prize}</p>
                    <form className="form-roleta" onSubmit={handleSubmit}>
                        <div className="form-group">
                            <input type="text" id="name" value={name} disabled={rodando} placeholder="Informe o seu nome *" title="Nome" onChange={e=>setName(e.target.value)} />
                        </div>
                        <div className="form-group">
                            <InputMask placeholder="Telefone *" disabled={rodando} title="Telefone" id="telefone" mask="(99) 99999 9999" maskChar=" " onChange={e=>setTelefone(e.target.value)} />
                        </div>
                        <div className="form-group">
                            <input type="email" id="email" value={email} disabled={rodando} placeholder="Qual o seu melhor e-mail? (opcional)" title="E-mail (opcional)" onChange={e=>setEmail(e.target.value)} />
                        </div>
                        {rodando?(
                            <button type="submit" disabled>
                                Aguarde...
                            </button>
                        ):(
                            <button type="submit">
                                Girar a roleta
                            </button>
                        )}
                    </form>
                </>
            )}
        </>
    );
}
